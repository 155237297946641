/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

:root {
  --color-text: #999;
  --color-text-dimmed: #666;
  --color-text-bright: #fff;
  --color-background: #000;
  --font-primary: 'Roboto Condensed';
  --font-secondary: 'Roboto';
  --font-size: 20px;
  --font-size-xsmall: 0.75rem;
  --font-size-small: 1rem;
  --font-size-medium: 1.5rem;
  --font-size-large: 3.25rem;
  --font-size-xlarge: 3.75rem;
  --gap-body-top: 60px;
  --gap-body-right: 60px;
  --gap-body-bottom: 60px;
  --gap-body-left: 60px;
  --gap-modules: 30px;
}

html {
  cursor: none;
  overflow: hidden;
  background: var(--color-background);
  user-select: none;
  font-size: var(--font-size);
}
